import React from "react";
import "../scss/aboutas.scss"

import AK from "../img/creater/AndreyKochkin.jpg"
import OD from "../img/creater/OleksandrDyachenko.jpg"
import RS from "../img/creater/RomanStarikov.jpg"
import TL from "../img/creater/TL.png"
import { useTranslation, Trans } from "react-i18next";

function Creater(){
    const { t } = useTranslation();
    const creaters = t('about-as.creater', { returnObjects: true });
    const icons = [{img:AK}, {img:TL},{img:OD}, {img:RS}];
    const creatersWithPhoto = creaters.map((elem, idx) => {
        return {
            ...elem,
            img: icons[idx].img
        }
    });
    return(
        creatersWithPhoto.map((el)=>{
            return(
                <div className="create" key={el.id}>
                    <img className="create_photo" src={el.img} alt="" />
                    <span className="create_name">{el.name}</span>
                    <span className="create_faq">{el.faq}</span>
                </div>
            )
        })
    )
}


export function AboutAs(){
    const { t } = useTranslation();
    const organizationsList = t('about-as.list', { returnObjects: true });
    return(
        <div className="about-as">
            <span className="about-as_title title">{t('about-as.title')}</span>
            <span className="about-as_sub-title">
                <Trans i18nKey="about-as.sub-title">
                    Ми благодійна ініціатива організацій<br/> з України, Латвії та Великої Британії
                </Trans>
            </span>
            <div className="about-as_list">
                <div className="list">
                    <ul>
                        {organizationsList.map((el, idx) => <li key={idx}>{el}</li>)}
                    </ul>
                </div>
            </div>
            <div className="about-as_modail-info">
                <div className="boolu"></div>
                <span>{t('about-as.modail')}</span>
                <div className="boolb"></div>
            </div>
            <div className="about-as_creater">
                <Creater></Creater>
            </div>
        </div>
    )
}