import React  from "react"
import "../scss/lookingpartner.scss"
import { useTranslation, Trans } from "react-i18next"
import Img2 from "../img/lookingpartner/PexelsPh.jpg"
import Img1 from "../img/lookingpartner/PexelsPhot.jpg"
import Img0 from "../img/lookingpartner/PexelsPhoto.jpg"

export function LookingPartner(){
    const { t } = useTranslation();
    const modail = t('look-parner.modail', { returnObjects: true });
    const icons = [{img:Img0}, {img:Img1}, {img:Img2}];
    const modailWithIcons = modail.map((elem, idx) => {
        return {
            ...elem,
            img: icons[idx].img
        }
    });

    return(
        <div className="look-parner">
            <div className="look-parner_title">
                <span className="title">
                    <Trans i18nKey="look-parner.title">
                        Ми в пошуках<br/> партнерів по проєкту:
                    </Trans>
                </span>
            </div>
            <div className="look-parner_modail ">
                {
                    modailWithIcons.map((el)=>{
                        return(
                            <div className="case-conten-lp" key={el.id}>
                                <div className="case-conten-lp_text">
                                    <span>{el.title}</span>
                                    <span>{el.info}</span>
                                </div>
                                <img className="case-conten-lp_img" src={el.img} alt=""/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}