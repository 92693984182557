import React, { useEffect, useState } from "react";
import "../scss/footer.scss"
import AW from "../img/footer/AppStoreW.svg"
import GW from "../img/footer/GoogleStoreW.svg"
import LW from "../img/footer/logoW.svg"
import Marck from "../img/footer/marck.svg"
import { resize } from "./header";

import { useTranslation } from 'react-i18next';

function WStor(){
    return(
        <div className='stor-case'>  
            <div className='stor-case_button'>
                <a href="https://apps.apple.com/us/app/charity-hero/id1612487637"><img src={AW} alt="" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.magnetto.CharityHero&hl=ru&gl=US"><img src={GW} alt="" /></a>
            </div>
            <span className='stor-case_title' style={{color:"#ffffff"}}>download app</span>
        </div>
    )
}


export function Footer(){

    const { t } = useTranslation();

    const [width, setWidth] = useState(false)

    useEffect(()=>{
        resize(setWidth)

        window.addEventListener("resize",()=>{
            resize(setWidth)
        })
    },[])

    return(
        <div className="bg-footer">
            <div className="footer">
                <div className="footer_case">
                    <img src={LW} alt="" />
                    <nav className="nav-footer">
                        <button id="charityHero">{t('header.nav.ofCharityHero')}</button>
                        <button id="helpAFU">{t('header.nav.help')}</button>
                        <button id="partner">{t('header.nav.partner')}</button>
                        <button id="contact">{t('header.nav.contact')}</button>
                    </nav>
                </div>
                {width? <WStor></WStor> :"" }
                <span>{t('footer.title')}</span>
                <div className="footer_case">
                    <div className="footer_rights">
                        <span className="footer-marck">designed by deniels berzinsh 2022<img src={Marck} alt=""/></span>
                        <span className="footer-marck">Charity Lab Foundation © 2023 All rights reserved</span>
                    </div>
                    {/* {width? "": <WStor></WStor> } */}
                </div>
            </div>
            {/* <p>{t('description.part2')}</p> */}
        </div>
    )
}

