import React from "react";
import '../scss/ofcharityhero.scss'
import askHelp from "../img/charityhero/askHelp.svg"
import FH from "../img/charityhero/FH.svg"
import HUM from "../img/charityhero/HUM.svg"
import KJ from '../img/charityhero/KJ.svg'
import imgOCH from '../img/charityhero/unsplash_qhlmymt14Ys.jpg'
import {ButtonMakeADeposit} from '../scripts/modail'
import { useTranslation } from "react-i18next";

function CHCase(){
    const { t } = useTranslation();
    const icons = [{img: askHelp}, {img: FH}, {img: HUM}, {img: KJ}];
    const info = t('of-charity-hero_title.info', { returnObjects: true });

    const infoWithIcons = info.map((elem, idx) => {
        return {
            ...elem,
            img: icons[idx].img
        }
    });

    return(
        infoWithIcons.map((elem) => {
            return (
                <div key={elem.id} className="of-charity-hero_info case-och">
                    <img src={elem.img} alt="" className="case-och_img"/>
                    <div className="case-och_info-case">
                        <span className="title-och">{elem.title}</span>
                        <span className="info-och">{elem.info}</span>
                    </div>
                </div>
            )
        })
    )
}

export function CharityHero(props){
    const { t } = useTranslation();

    return(
        <div id="hero-about" className="of-charity-hero">
            <img src={imgOCH} alt="" className="of-charity-hero_img"/>
            <div className="of-charity-hero_title">
                <span className="title">{t('of-charity-hero_title.title')}</span>
            </div>
            <CHCase/>
            <ButtonMakeADeposit location={props.location}>{t('button-donation')}</ButtonMakeADeposit>
        </div>
    )
}