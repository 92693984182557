import React, {useEffect, useState} from 'react';
import { HeaderBig, HeaderMini, HomeContent } from './header';
import "../scss/index.scss"
import {CharityHero} from './charityhero'
import { HWCH } from './hwch';
import { HelpFofUa, NNUa } from './uaneednow';
import { ButtonUp, CarouselPartner } from './modail';
import { LookingPartner } from './lookingpartner';
import { AboutAs } from './aboutas';
import { ContactAs } from './contacas';
import { Footer } from './footer';
import "../scss/media.scss"
import BGTop from '../img/bgtop.png'
import BGMidle from "../img/bg2.png"
import BGBottum from "../img/bg4.png"
import { Loade } from './load';
import { useTranslation } from 'react-i18next';

const IP_CHECKER_URL = "https://www.cloudflare.com/cdn-cgi/trace";

function App() {
    const [userLocation, setUserLocation] = useState("ua");

    const { i18n } = useTranslation();

    const convertResponseStringToObject = (dataString) => {
        const userLocationData = dataString.trim().split('\n').reduce((obj, pair) => {
            pair = pair.split('=');
            return  obj[pair[0]] = pair[1], obj
        }, {});
        
        const location = userLocationData.loc.toLowerCase();
        setUserLocation(location);
    }

    const fetchError = (error) => {
        console.error("Error fetching data:", error);
        setUserLocation(i18n.resolvedLanguage);
    }

    useEffect(()=>{
        const fetchIpData = async () => {
            await fetch(IP_CHECKER_URL)
                .then(resp => {
                    if (resp.ok) {
                        return resp.text()
                    }
                    throw resp;
                })
                .then(data => convertResponseStringToObject(data))
                .catch(error => fetchError(error));
        }
        fetchIpData();
    },[])
    return(
        <>
            <Loade></Loade>
            <div className='top-backgraund'><div><img src={BGTop} alt=""/></div></div>
            <HeaderMini></HeaderMini>
            <HeaderBig></HeaderBig>
            <HomeContent location={userLocation}/>
            <CharityHero location={userLocation}></CharityHero>
            <HWCH></HWCH>
            <NNUa></NNUa>
            <div className='midle-backgraund'><div><img src={BGMidle} alt="" /></div></div>
            <HelpFofUa></HelpFofUa>
            <CarouselPartner></CarouselPartner>
            <LookingPartner></LookingPartner>
            <AboutAs></AboutAs>
            <div className='bottun-backgraund'><div><img src={BGBottum} alt="" /></div></div>
            <ContactAs></ContactAs>
            <Footer></Footer>
            <ButtonUp></ButtonUp>
        </>
    )
}

export default App;